export function getFullName(item: {
  firstName?: string | undefined
  lastName?: string | undefined
  [key: string]: any
} | undefined): string {
  if (!item)
    return ''

  const { firstName, lastName } = item
  return `${firstName ?? ''} ${lastName ?? ''}`.trim()
}

export function decodeHTMLEntities(text: string) {
  if (!text)
    return ''

  const element = document.createElement('div')
  let str = text
  // strip script/html tags
  str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
  str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
  element.innerHTML = str
  str = element.textContent
  element.textContent = ''
  element.remove()

  return str
}
